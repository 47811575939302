import auth0, { Auth0Callback, Auth0Error, WebAuth } from 'auth0-js'
import { navigate } from 'gatsby'
import { getUserMe } from './user'

const isBrowser = typeof window !== 'undefined'
const webAuthConfig = {
    domain: process.env.GATSBY_AUTH0_DOMAIN || '',
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID || '',
    redirectUri: process.env.GATSBY_AUTH0_CALLBACK_URL,
    responseType: 'token id_token',
    scope: 'openid profile email',
    audience: process.env.GATSBY_AUTH0_AUDIENCE,
}

const auth: WebAuth | null = isBrowser ? new auth0.WebAuth(webAuthConfig) : null

const isAuthenticated = () => {
    if (!isBrowser) {
        return
    }
    return localStorage.getItem('isLoggedIn') === 'true'
}

const login = () => {
    localStorage.removeItem('auth0LogoutRoute')
    if (!isBrowser) {
        return
    }
    if (!auth) {
        return
    }
    auth.authorize()
    getUserMe(false)
}

// interface AuthResultProps {
//     accessToken: string
//     idToken: string
//     expiresIn: number
//     expireIn: string
//     refreshToken: string
//     idTokenPayload: any
// }

const setSession = (
    cb = () => {
        return
    }
): Auth0Callback<never, Auth0Error> => {
    return (err, authResult) => {
        if (!isBrowser) {
            return
        }
        if (err) {
            navigate('/settings')
            cb()
            return
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
            const expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
            localStorage.setItem('auth0AccessToken', authResult.accessToken)
            localStorage.setItem('auth0RefreshToken', authResult.refreshToken)
            localStorage.setItem('auth0IdToken', authResult.idToken)
            localStorage.setItem('auth0ExpiresAt', JSON.stringify(expiresAt))
            localStorage.setItem('auth0ExpiresIn', authResult.expireIn)
            localStorage.setItem(
                'auth0User',
                JSON.stringify(authResult.idTokenPayload)
            )
            localStorage.setItem('isLoggedIn', 'true')
            navigate('/settings/')
            cb()
        }
    }
}

const handleAuthentication = () => {
    if (!isBrowser) {
        return
    }
    if (!auth) {
        return
    }
    auth.parseHash(setSession())
}

const getProfile = () => {
    let value = localStorage.getItem('auth0User')
    if (!value) {
        value = '{"business_id": null, "name": null}'
    }
    return JSON.parse(value)
}

const logout = (returnTo?: string) => {
    localStorage.setItem('isLoggedIn', 'false')
    localStorage.removeItem('auth0AccessToken')
    localStorage.removeItem('auth0RefreshToken')
    localStorage.removeItem('auth0IdToken')
    localStorage.removeItem('auth0ExpiresAt')
    localStorage.removeItem('auth0ExpiresIn')
    localStorage.removeItem('auth0User')
    if (returnTo) {
        localStorage.setItem('auth0LogoutRoute', returnTo)
    }
    if (!auth) {
        return
    }
    const location = (window as any).location
    auth.logout({ returnTo: `${location?.origin}/auth/logout` })
}

const getAuthorizationHeader = () => {
    const expiresAt = JSON.parse(localStorage.getItem('auth0ExpiresAt') || '0')
    if (expiresAt < new Date().getTime() / 1000) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const location = (window as any).location
        if (location.pathname.startsWith('/settings')) {
            navigate('/settings')
        }
        console.log('Token expired')
    }
    const accessToken = localStorage.getItem('auth0AccessToken')
    const obj = {
        headers: {},
    }
    if (accessToken) {
        obj.headers = {
            Authorization: `Bearer ${accessToken}`,
        }
    }
    return obj
}

export {
    handleAuthentication,
    getAuthorizationHeader,
    isAuthenticated,
    login,
    logout,
    getProfile,
}
